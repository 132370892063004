// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.jsx");
}
// REMIX HMR END

import { Links, Meta, Outlet, Scripts, ScrollRestoration, useNavigate, useLocation, useLoaderData } from '@remix-run/react';
import { ClientOnly } from 'remix-utils';
import { useEffect, useState } from 'react';
import { ConfigProvider, Layout, Menu, message, theme, Button } from 'antd';
import en_US from 'antd/locale/en_US';
import styles from './styles/index.css';
import lightbox from './styles/lightbox.css';
import general from './styles/general.css';
import ApiService from './services/ApiService';
import { getSession } from './session';
import logo from './images/TDG LOG NEW.png';
import authenticator from './services/authenticator';
import { ArrowLeftOutlined } from '@ant-design/icons';
const {
  Header,
  Content,
  Footer,
  Sider
} = Layout;
export function links() {
  return [{
    rel: 'stylesheet',
    href: styles
  }, {
    rel: 'stylesheet',
    href: 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css'
  }, {
    rel: 'stylesheet',
    href: lightbox
  }, {
    rel: 'stylesheet',
    href: general
  }];
}
function getItem(label, key, icon, children) {
  return {
    label,
    key,
    icon,
    children
  };
}
function parseCookies(cookieHeader) {
  return cookieHeader ? Object.fromEntries(cookieHeader.split('; ').map(c => {
    const [key, ...v] = c.split('=');
    return [key, v.join('=')];
  })) : [];
}
export const loader = async ({
  params,
  request
}) => {
  const cookieHeader = request.headers.get('Cookie');
  const url = new URL(request.url);
  const pathname = url.pathname;
  if (url.searchParams.get('logout')) {
    return {
      status: 200,
      success: true
    };
  }
  if (!cookieHeader && pathname !== '/') {
    return {
      status: 401,
      success: false
    };
  }
  const cookies = parseCookies(cookieHeader);
  const sessionDrive = cookies?.__session_drive;
  if (!sessionDrive && pathname !== '/') {
    return {
      status: 401,
      success: false
    };
  }
  const session = await getSession(request.headers.get('Cookie'));
  const companyId = session.get('companyId');
  const userPermission = await authenticator.getUserPermissions(session);
  return {
    permissions: userPermission.permissions,
    userSideBarLinks: userPermission.userSideBarLinks,
    companyId
  };
};
export default function App() {
  _s();
  const location = useLocation();
  const {
    pathname,
    search
  } = location;
  const {
    userSideBarLinks,
    permissions,
    companyId
  } = useLoaderData();
  const [items, setItems] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys] = useState(pathname.replace(/^\//, '') + search);
  // console.log('search', search)
  const navigate = useNavigate();
  const {
    token: {
      colorBgContainer
    }
  } = theme.useToken();
  const registerOrHomePage = pathname === '/' || pathname === '/register';
  const excludedPaths = ['/campaign/select', '/customers', '/settings/brands', '/settings/pointshop', '/settings/rewards', '/settings/code/generator', '/item/delivery/management', '/rewards/transfer'];
  const shouldHideArrow = excludedPaths.includes(location.pathname);
  useEffect(() => {
    if (userSideBarLinks) {
      if (search && search.includes('login') && !userSideBarLinks.length) {
        return message.error('You do not have any permission to access this website. Please contact your administrator.');
      }
      const settingLinks = userSideBarLinks.filter(e => e.path.startsWith('settings/'));
      const sidebarLinks = userSideBarLinks.filter(e => !e.path.startsWith('settings/')).map(e => {
        const key = Object.keys(e).find(k => k.startsWith('can_view'));
        return getItem(e[key], e.path, null, null);
      });

      // setting links
      const settingItems = settingLinks?.length && settingLinks.map(e => {
        const key = Object.keys(e).find(k => k.startsWith('can_view'));
        return getItem(e[key], e.path, null, null);
      });
      if (settingItems?.length) {
        setItems([...sidebarLinks, getItem('Settings', 'sub1', null, settingItems)]);
      } else {
        setItems(sidebarLinks);
      }
    }
  }, [userSideBarLinks, search]);
  useEffect(() => {
    if (search && search.includes('logout')) {
      return;
    }
    // check user login success and navigate to the first accessible link
    if (items.length && registerOrHomePage) {
      if (items[0] && items[0].children) {
        return navigate(`/${items[0].children[0].key}`);
      }
      return navigate(`/${items[0].key}`);
    }
  }, [items, navigate, registerOrHomePage, search]);
  useEffect(() => {
    if (companyId) {
      localStorage.setItem('drive-companyId', companyId);
    }
  }, [companyId]);
  const logout = async () => {
    try {
      const resData = await ApiService.put(`/api/operator/auth`);
      const logoutUrl = `/?companyId=${resData?.companyId}&logout=true`;
      navigate(logoutUrl);
    } catch (error) {
      console.error('Error:', error);
      message.error(error.message);
    }
  };
  const handleBackClick = () => {
    navigate(-1);
  };
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <ClientOnly>
          {() => <>
              <ConfigProvider locale={en_US}>
                <Layout className="hundredVh">
                  {registerOrHomePage ? null : <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>

                      <div className="demo-logo-vertical" />
                      <Menu theme="dark" defaultSelectedKeys={[selectedKeys]} defaultOpenKeys={[selectedKeys]} mode="inline" items={items.map(e => {
                  if (e.children) {
                    return {
                      ...e,
                      label: e.label,
                      children: e.children.map(i => {
                        return {
                          ...i,
                          label: i.label
                        };
                      })
                    };
                  }
                  return {
                    ...e,
                    label: e.label
                  };
                })} onClick={item => {
                  navigate(`/${item.key}`);
                }} />

                    </Sider>}

                  <Layout>
                    <Header style={{
                  background: colorBgContainer
                }} className="d-flex justify-content-between">

                      <div className="d-flex justify-content-between">
                        {!shouldHideArrow && <div style={{
                      width: '50px',
                      cursor: 'pointer'
                    }} onClick={handleBackClick}>

                            <ArrowLeftOutlined style={{
                        fontSize: '20px'
                      }} />
                          </div>}
                        <div>
                          <img src={logo} alt="logo" style={{
                        objectFit: 'contain',
                        width: '7rem'
                      }} />

                        </div>
                      </div>
                      <div>
                        {pathname === '/' || pathname === '/register' ? null : <Button onClick={() => logout()}>Logout</Button>}
                      </div>
                    </Header>
                    <Content>
                      <Outlet context={{
                    permissions,
                    companyId
                  }} />
                    </Content>
                    <Footer className="text-center">
                      The D Group ©{new Date().getFullYear()}
                    </Footer>
                  </Layout>
                </Layout>
              </ConfigProvider>
            </>}
        </ClientOnly>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>;
}
_s(App, "bSYsM+sroQvXcSTAU9rLcEFqqI0=", false, function () {
  return [useLocation, useLoaderData, useNavigate, theme.useToken];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;